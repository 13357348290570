@import '../../base/base';
/*      Alert       */

.alert {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 0.9375rem;

  .btn {
    margin-right: 27px;

    &:hover {
      box-shadow: none;
    }
  }

  .alert-icon svg {
    vertical-align: middle;
    width: 33px;
    height: 33px;
    stroke-width: 1.2;
  }

  .btn-close {
    color: #000;
    opacity: 1;
    width: 18px;
    background: transparent;
    padding: 13px 12px;
    box-shadow: none;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

/*Default Alerts*/

.alert-primary {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.alert-warning {
  color: #fff;
  background-color: $warning;
  border-color: $warning;
}

.alert-success {
  color: #fff;
  background-color: #00ab55;
  border-color: #00ab55;
}

.alert-info {
  color: #fff;
  background-color: $info;
  border-color: $info;
}

.alert-danger {
  color: #fff;
  background-color: $danger;
  border-color: $danger;
}

.alert-dark {
  color: #fff;
  background-color: $dark;
  border-color: $dark;
}

/*Outline Alerts*/

.alert-outline-primary {
  border-color: $primary;
  border-radius: 5px;
}

.alert-outline-warning {
  border-color: #dea82a;
  border-radius: 5px;
}

.alert-outline-success {
  border-color: #00ab55;
  border-radius: 5px;
}

.alert-outline-info {
  border-color: #009eda;
  border-radius: 5px;
}

.alert-outline-danger {
  border-color: $danger;
  border-radius: 5px;
}

.alert-outline-dark {
  border-color: #454656;
  border-radius: 5px;
}

.alert {
  &.alert-light .close {
    color: #0e1726;
  }

  &.solid-alert-3 .close, &.solid-alert-4 .close {
    color: #000;
  }
}

.hide-default {
  display: none;
}

/*      Light Alert         */

.btn-light {
  border-color: transparent;
}

.alert-light-primary {
  color: $primary;
  background-color: #eceffe;
  border-color: rgb(67, 97, 238, 0.55);

  svg.close {
    color: $primary;
  }
}

.alert-light-warning {
  color: $warning;
  background-color: #fcf5e9;
  border-color: rgb(226, 160, 63, 0.55);

  svg.close {
    color: $warning;
  }
}

.alert-light-success {
  color: #00ab55;
  background-color: #e6f6ee;
  border-color: rgb(26, 188, 156, 0.55);

  svg.close {
    color: #00ab55;
  }
}

.alert-light-info {
  color: $info;
  background-color: #e6f4ff;
  border-color: rgb(33, 150, 243, 0.55);

  svg.close {
    color: $info;
  }
}

.alert-light-danger {
  color: $danger;
  background-color: #fbeced;
  border-color: rgb(231, 81, 90, 0.55);

  svg.close {
    color: $danger;
  }
}

.alert-light-dark {
  color: #515365;
  background-color: #eaeaec;
  border-color: rgb(59, 63, 92, 0.55);

  svg {
    &.close {
      color: $dark;
    }

    &:not(.close) {
      color: #fff !important;
    }
  }
}

/*  Background Alerts      */

.alert-background {
  color: #fff;
  background: #fff url(../../../img/ab-1.jpeg) no-repeat center center;
  background-size: cover;
  border: none;
}

/*  Gradient Alerts      */

.alert-gradient {
  color: #fff;
  border: none;
  background-size: cover;
  background-image: linear-gradient(135deg, #bc1a4e 0%, #004fe6 100%);
}

/* Custom Alerts */

/* Default */

.custom-alert-1 {
  background-color: #7d30cb;
  border-radius: 5px;
  color: #fff;

  .btn-close {
    top: 9px;
  }

  .alert-icon {
    margin-right: 25px;
  }

  .media-body {
    display: flex;
    justify-content: space-between;
  }

  .alert-text {
    margin-right: 10px;

    strong, span {
      vertical-align: sub;
    }
  }
}

/*  Alert with Icon */

.alert-icon-left {
  border-left: 64px solid;

  svg:not(.close) {
    color: #FFF;
    width: 4rem;
    left: -4rem;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.alert-icon-right {
  border-right: 64px solid;

  svg:not(.close) {
    color: #FFF;
    width: 4rem;
    right: -4rem;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  i {
    float: left;
    margin-right: 7px;
  }
}

.alert {
  &[class*=alert-arrow-]:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    border-left: 8px solid;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left-color: inherit;
    margin-top: -8px;
  }

  &.alert-arrow-right:before {
    left: auto;
    right: 0;
    border-left: 0;
    border-right: 8px solid;
    border-right-color: inherit;
  }
}

@media (max-width: 575px) {
  .custom-alert-1 .media-body {
    display: block;
  }

  .alert .btn {
    margin-top: 8px;
  }
}